import { Routes, Route, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import './App.css';
import './index-74579d77.css'
import LoginPage from '../LoginPage/LoginPage';
import MainPage from '../MainPage/MainPage';
import { GeneralContext } from '../../contexts/GeneralContext';
import LogoutPage from '../LogoutPage/LogoutPage';
import { siteAddr, ssoAddr } from '../../consts';

import { generateCodeChallenge, generateCodeVerifier } from '../../providers/CryptoProvider';

function encrypt(text, key){
    return [...text].map((x, i) => 
    (x.codePointAt() ^ key.charCodeAt(i % key.length) % 255)
     .toString(16)
     .padStart(2,"0")
   ).join('')
}

function decrypt(text, key){
    return String.fromCharCode(...text.match(/.{1,2}/g)
     .map((e,i) => 
       parseInt(e, 16) ^ key.charCodeAt(i % key.length) % 255)
     )
}


function App() {
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'idtoken']);
  const [token, setToken] = useState(cookies.token ? decrypt(cookies.token, 'Wild elephants flying to the moon') : undefined);
  const [idtoken, setIdToken] = useState(
    cookies.idtoken ? decrypt(cookies.idtoken, 'Wild elephants flying to the moon'): undefined
  );
  const navigate = useNavigate()

	useEffect(()=>{
		console.log("UseEffect CODE")
		if (!sessionStorage.getItem('v')) {
			const CODE_VERIFIER = generateCodeVerifier()
			const CODE_CHALLENGE = generateCodeChallenge(CODE_VERIFIER)
			sessionStorage.setItem('v', CODE_VERIFIER);
			sessionStorage.setItem('c', CODE_CHALLENGE);
		}
	}, [])

  const handleLogIn = (res) => {
	console.log('start onlogin')
    setCookie('token', encrypt(res.access_token, 'Wild elephants flying to the moon'), { maxAge: res.expires_in });
    setToken(res.access_token);
	setCookie('idtoken', encrypt(res.id_token, 'Wild elephants flying to the moon'), { maxAge: res.expires_in });
	setIdToken(res.id_token);
	sessionStorage.setItem("s","1");
	console.log('stop onlogin')
	navigate('/', { replace: true })
  };

  const logout = () => {
	const CODE_VERIFIER = generateCodeVerifier()
	const CODE_CHALLENGE = generateCodeChallenge(CODE_VERIFIER)
    removeCookie('token');
    removeCookie('idtoken');
	removeCookie('phone');
	removeCookie('code');
	navigate('/logout', { replace: true })
    setToken(undefined);
    window.location.replace(`${ ssoAddr }realms/ssottkid/protocol/openid-connect/logout?id_token_hint=${ idtoken }&post_logout_redirect_uri=${ siteAddr }`)
	sessionStorage.setItem('s', '1');
	sessionStorage.setItem('v', CODE_VERIFIER);
	sessionStorage.setItem('c', CODE_CHALLENGE);


  }
  const resetState = () => {
	sessionStorage.removeItem("s")
  }

  if ((!sessionStorage.getItem("s") && window.location.pathname!=='/login') || (!token && window.location.pathname!=='/logout')) {
	console.log("Go to /login")
	navigate('/login', { replace: true })
	return (
		<GeneralContext.Provider
		value={{
		  token,
		  setToken,
		  idtoken,
		  setIdToken,
		  encrypt,
		}}
	  >
	  	<LoginPage onLogin={handleLogIn} />
	  </GeneralContext.Provider>
	)
  }
  console.log('app');
  return (
    <GeneralContext.Provider
      value={{
        token,
        setToken,
        idtoken,
        setIdToken,
		encrypt,
      }}
    >
      <div className='wrapper'>
        <div className='navbar'>
          <a href='/' onClick={resetState}>На главную</a>
        
          <a href='/' className='logo'>
            <img src='https://myttk.ru/upload/logo/ttks/LogoTTK.jpg' />
          </a>
		  { token ? <a href='#' className='logout' onClick={logout}>
            Выйти
          </a> : ''}
        </div>
        <div className="container">
          <Routes>
            <Route path='/'  onLogin={handleLogIn} element={<MainPage />} />
            <Route
              path='/login'
              element={<LoginPage onLogin={handleLogIn} />}
            />
			<Route path='/logout' element={<LogoutPage />} />
          </Routes>
        </div>
      </div>
    </GeneralContext.Provider>
  );
}

export default App;
