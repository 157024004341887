import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GeneralContext } from '../../contexts/GeneralContext';
import { useCookies } from 'react-cookie';
import InputMask from 'react-input-mask';
import { ssoAddr, siteAddr } from '../../consts';
import {
  generateState,
} from '../../providers/CryptoProvider';

const CODE_CHALLENGE_METHOD = 'S256';

function LoginPage({ onLogin }) {
  const navigate = useNavigate();
  const [codeForm, setCodeForm] = useState(false);
  const [authBtn, setAuthBtn] = useState(true);
  const [phone, setPhone] = useState();
  const [error, setError] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'idtoken']);
  const { token, setToken, idtoken, setIdToken, encrypt } =
    useContext(GeneralContext);

  useEffect(() => {
    async function fetchData() {
      var CODE_VERIFIER = sessionStorage.getItem('v');

      const queryParameters = new URLSearchParams(window.location.search);
      const type = queryParameters.get('session_state');
      const code = queryParameters.get('code');
      if (code) {
        console.log('code founded');
        var details = {
          client_id: 'ttk-client',
          //client_secret: 'OEN34S8b23HbrwH5zLnMe8WyILNTqHxB',
          code_verifier: CODE_VERIFIER,
          code: code,
          grant_type: 'authorization_code',
          redirect_uri: `${siteAddr}login`,
        };

        var formBody = [];
        for (var property in details) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');
        const data = await fetch(
          `${ssoAddr}realms/ssottkid/protocol/openid-connect/token`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formBody,
          }
        );
        let result = await data.json();
        console.log('will be onlogin');
        onLogin(result);
        return;
      }
    }
    fetchData();
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  async function signin() {
    setAuthBtn(false);
    setCodeForm(false);
	var CODE_CHALLENGE = sessionStorage.getItem('c')
	
    if (token) {
      let tokenarr = token.split('.');
      if (tokenarr.length > 0) {
        setToken(token);
        setIdToken(idtoken);
        sessionStorage.setItem('s', '1');
        console.log('Go to /');
        navigate('/', { replace: true });
        return;
      }
    } else {
      /*
      window.location.replace(
        `${ssoAddr}realms/ssottkid/protocol/openid-connect/auth?scope=openid+profile+email+offline_access&client_id=ttk-client&redirect_uri=${siteAddr}login&response_type=code`
      );*/
      window.location.replace(
        `${ssoAddr}realms/ssottkid/protocol/openid-connect/auth?scope=openid+profile+email+offline_access&client_id=ttk-client&redirect_uri=${siteAddr}login&response_type=code&state=${generateState()}&code_challenge=${CODE_CHALLENGE}&code_challenge_method=${CODE_CHALLENGE_METHOD}`
      );
    }
  }

  /*
 POST /realms/{realmName}/protocol/openid-connect/token 
 Content-Type: application/x-www-form-urlencoded grant_type=password&
 phone_number=$PHONE_NUMBER&code=$VERIFICATION_CODE&client_id=$CLIENT_ID&
 client_secret=$CLIENT_SECRECT 
  */

  return (
    <main className='flex justify-center py-4 sm:pt-12'>
      <div className='grid basis-80 gap-4'>
        <div className='rounded-2xl bg-bg_primary p-5 lg:rounded-3xl lg:p-8 grid gap-6 !p-8'>
          <img src='https://myttk.ru/upload/logo/ttks/LogoTTK.jpg' />
          <h4 className='text-xl leading-6 tracking-[0.2px] font-bold'>
            Вход в личный кабинет
          </h4>
          <form className='grid gap-6' onSubmit={handleSubmit}>
            <div className='grid gap-3'>
              <div className='grid'>
                {!codeForm && !authBtn ? (
                  <label className='relative grid'>
                    Переход на страницу авторизации...
                  </label>
                ) : !authBtn ? (
                  <label className='relative grid'>
                    Введите код подтверждения
                    <InputMask
                      mask='999999'
                      maskChar='_'
                      key='code'
                      className='rounded-xl border-2 border-separator outline-none hover:border-ctrl_ttk focus-visible:border-ctrl_ttk disabled:pointer-events-none disabled:border-separator disabled:text-text_secondary px-4 py-2'
                      type='text'
                      inputMode='code'
                      autoComplete='code'
                      placeholder='999999'
                      name='code'
                    />
                  </label>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className='grid gap-3'>
              {!codeForm && !authBtn ? '' : !authBtn ? '' : ''}
              {authBtn ? (
                <button
                  className='flex items-center justify-center gap-2 overflow-hidden hover:before:absolute hover:before:inset-0 hover:before:bg-bg_overlay active:after:absolute active:after:inset-0 active:after:bg-bg_overlay disabled:pointer-events-none disabled:bg-ctrl_neutral_secondary disabled:text-text_tertiary bg-ctrl_ttk text-text_primary_inverted rounded-xl py-4 font-medium tracking-wide px-6 relative'
                  tabIndex='0'
                  onClick={signin}
                >
                  <span className='text-start'>Войти</span>
                </button>
              ) : (
                ''
              )}
            </div>
          </form>
          {error ? (
            <span class='text-xs font-medium text-text_secondary text-center'>
              Произошла внутренняя ошибка. Повторите запрос.
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
    </main>
  );
}

export default LoginPage;
