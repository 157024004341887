import { useContext, useEffect, useState } from 'react';
import { GeneralContext } from '../../contexts/GeneralContext';
//import { useNavigate } from 'react-router-dom';

function LogoutPage() {
  const { token, setToken } = useContext(GeneralContext);
  const [user, setUser] = useState();

  return (
    <div>
        <div class='container'>
		<div className='bigMessage'>Ваша сессия успешно завершена</div>
     
        </div>
    </div>
  );
}

export default LogoutPage;
