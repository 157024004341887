import { useContext, useEffect, useState } from 'react';
import { GeneralContext } from '../../contexts/GeneralContext';
import { useCookies } from 'react-cookie';
import { ssoAddr } from '../../consts';
import { generateCodeChallenge, generateCodeVerifier } from '../../providers/CryptoProvider';
//import { useNavigate } from 'react-router-dom';

function MainPage() {
  const { token, setToken } = useContext(GeneralContext);
  const [user, setUser] = useState();
  const [showToken, setShowToken] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'idtoken']);

  console.log('MainPage started')
  function toggleToken () {
	setShowToken(!showToken)

  }


  useEffect(() => {
	if (!cookies.token || cookies.token.length < 10) {
		removeCookie('token');
		removeCookie('idtoken');
		removeCookie('phone');
		removeCookie('code');
		setToken(undefined);
	}
    fetch(
      `${ ssoAddr }realms/ssottkid/protocol/openid-connect/userinfo?scope=openid+email+profile`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(async (res) => {
      console.log(res);
      if (token && res.status === 401) {
		const CODE_VERIFIER = generateCodeVerifier()
		const CODE_CHALLENGE = generateCodeChallenge(CODE_VERIFIER)
		sessionStorage.setItem('v', CODE_VERIFIER);
		sessionStorage.setItem('c', CODE_CHALLENGE);
        console.log('401 error');
        removeCookie('token');
        removeCookie('idtoken');
		removeCookie('phone');
		removeCookie('code');
        setToken(undefined);
      }
	  if (token && res.status === 200) {
        var userInfo = await res.json();
		setCookie(
			'phone',
			userInfo.preferred_username,
			{ maxAge: 360 }
		  );
		setCookie(
			'code',
			userInfo.sub,
			{ maxAge: 360 }
		  );
        setUser(userInfo);
      }
    });
  }, []);
  console.log('Return')
  return (
    <div>
      <h2>Личный кабинет</h2>

      {user ? (
        <div className='userInfoBlock'>
          <table>
            <tbody>
			<tr>
                <td>
                  <b>Код пользователя: </b>
                </td>
                <td>{user.sub}</td>
              </tr>
              <tr>
                <td>
                  <b>Номер телефона</b>
                </td>
                <td> {user.preferred_username}</td>
              </tr>
              <tr>
                <td>
                  <b>Токен KeyCloak</b>
                </td>
                <td> <div>{token}</div></td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default MainPage;
