import CryptoJS from 'crypto-js';

export function generateState() {
    const length = 30;
    let stateValue = "";
    const alphaNumericCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const l = alphaNumericCharacters.length;
    for(var i=0; i<length; i++) {
        stateValue += alphaNumericCharacters.charAt(Math.floor(Math.random()*l));
    }
    return stateValue;
}

export function generateCodeVerifier() {
	var rand = new Uint8Array(32);
	crypto.getRandomValues(rand);
	var code_verifier = base64URL(new CryptoJS.lib.WordArray.init(rand));
	console.log(code_verifier)
	return code_verifier
  }
export function generateCodeChallenge(code_verifier) {
	const challenge = base64URL(CryptoJS.SHA256(code_verifier))
	console.log(challenge)
	return  challenge
  }
  function base64URL(string) {
	return string.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
  }